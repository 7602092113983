$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-download__link {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  text-transform: capitalize;
}

.sw-download__linkIconText {
  font-weight: 600;
  margin-right: 0.75em;
}

.sw-download__linkIcon {
  border-radius: 50%;
  height: rem(24);
  overflow: hidden;
  transition: background-color map-get($transitions, default);
  width: rem(24);

  @include media('>=desktop') {
    height: rem(52);
    width: rem(52);
  }

  .sw-icon {
    height: 100%;
    object-fit: cover;
    object-position: center;
    stroke: currentColor;
    stroke-width: 2;
    transition: all map-get($transitions, default);
    width: 100%;
  }
}

.sw-download[class*='-text'] {
  &.light-text {
    --sw-download-base-color: #{map-get($colors, light-text)};
    --sw-download-inverse-color: #{map-get($colors, dark-text)};
  }

  &.dark-text {
    --sw-download-base-color: #{map-get($colors, dark-text)};
    --sw-download-inverse-color: #{map-get($colors, light-text)};
  }

  .sw-download__link {
    color: currentColor;

    &:hover {
      .sw-download__linkIcon {
        background: var(--sw-download-base-color);
      }

      .sw-icon {
        color: var(--sw-download-inverse-color);
        stroke: var(--sw-download-base-color);
      }
    }
  }
}
